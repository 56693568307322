export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Tip",
    key: "Tip",
    minWidth: 280,
    // sortable: true,
  },
  {
    title: "CreatedOn",
    key: "CreatedOn",
    sortable: true,
    minWidth: 80,
  },

];
